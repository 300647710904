import React, { useState } from "react";
import SEO from "../../components/SEO";
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import { motion, transform } from "framer-motion";
import greenCheckIcon from "../../images/v3/icons/GreenCheckIcon.webp";
import heroSectionBg from "../../images/v3/multichannel/heroSectionBg.webp";
import heroSectionLeftImage from "../../images/v3/multichannel/heroSectionLeftImage.webp";
import heroSectionRightImage from "../../images/v3/multichannel/heroSectionRightImage.webp";
import pcHomepage from "../../images/v3/singaporeCrmHomepage/pcHomepage.png";
import freshsalesCrmImage from "../../images/v3/multichannel/freshsalesCrmImage.png";
import zohoImage from "../../images/v3/multichannel/zohoImage.webp";
import engageBayImage from "../../images/v3/multichannel/engageBayImage.webp";
import mondayCrmImage from "../../images/v3/multichannel/mondayCrmImage.webp";
import supportSystemListingImage1 from "../../images/v3/multichannel/supportSystemListingImage1.webp";
import supportSystemListingImage2 from "../../images/v3/multichannel/supportSystemListingImage2.webp";
import supportSystemListingImage3 from "../../images/v3/multichannel/supportSystemListingImage3.webp";
import supportSystemListingImage4 from "../../images/v3/multichannel/supportSystemListingImage4.webp";
import greenCheckOutline from "../../images/v3/icons/greenCheckOutline.webp";
import greenTickIcon from "../../images/v3/icons/greenTickIcon.svg";
import autoCaptureLeadsIcon from "../../images/v3/icons/autoCaptureLeadsIcon.webp";
import airCallIcon from "../../images/v3/icons/airCallIcon.webp";
import aiChatIcon from "../../images/v3/icons/aiChatIcon.webp";
import dashboardIcon from "../../images/v3/icons/dashboardIcon.webp";
import messageIcon from "../../images/v3/icons/messageIcon.webp";
import mobileIcon from "../../images/v3/icons/mobileIcon.webp";
import omniChannelIcon from "../../images/v3/icons/omniChannelIcon.webp";
import oppertunityIcon from "../../images/v3/icons/oppertunityIcon.webp";
import quotationIcon from "../../images/v3/icons/quotationIcon.webp";
import salesIcon from "../../images/v3/icons/salesIcon.webp";
import utmTrackingIcon from "../../images/v3/icons/utmTrackingIcon.webp";
import whatsAppFlowIcon from "../../images/v3/icons/whatsAppFlowIcon.webp";
import videoCallIcon from "../../images/v3/icons/videoCallIcon.webp";
import piplineIcon from "../../images/v3/icons/piplineIcon.webp";
import whatsappIcon from "../../images/v3/icons/whatsappIcon.webp";
import contactmanagementIcon from "../../images/v3/icons/contactManagementIcon.webp";
import cloudImage from "../../images/v3/multichannel/cloudImage.webp";
import reviewLogo from "../../images/v3/multichannel/707IncLogo.webp";
import toolsImage1 from "../../images/v3/multichannel/toolsImage1.webp";
import toolsImage2 from "../../images/v3/multichannel/toolsImage2.webp";
import toolsImage3 from "../../images/v3/multichannel/toolsImage3.webp";
import toolsImage4 from "../../images/v3/multichannel/toolsImage4.webp";
import benifitImage1 from "../../images/v3/multichannel/benifitImage1.webp";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import triangleArrow from "../../images/v3/icons/triangleArrow.webp";
import sectionBackground from "../../images/v3/singaporeCrmHomepage/sectionBackground.webp";
import CompanyCard from "../../components/v2/CompanyCard";

const useStyles = makeStyles((theme) => ({
  heroSection: {
    width: "100%",
    paddingTop: "40px",
    backgroundImage: `url(${heroSectionBg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      left: "0",
      top: "0",
      backgroundImage: `url(${heroSectionLeftImage})`,
      top: "0",
      height: "100%",
      width: "30%",
      backgroundPosition: "left",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      right: "0",
      top: "0",
      backgroundImage: `url(${heroSectionRightImage})`,
      top: "0",
      height: "100%",
      width: "20%",
      backgroundPosition: "right",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    },
    "@media only screen and (max-width: 600px)": {
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "@media only screen and (min-width:768px) and (max-width:990px)": {
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
  },
  heroSectionInner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "35px",
    width: "70%",
    margin: "0 auto",
    textAlign: "center",
    "& h1": {
      margin: "0",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      gap: "25px",
    },
    "@media only screen and (min-width:768px) and (max-width:990px)": {
      width: "100%",
    },
  },
  highlight: {
    color: "#f95c3d",
  },
  list: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "100%",
    gap: "10px",
    "@media only screen and (max-width: 600px)": {
      gap: "10px",
    },
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    // width: "47%",
    gap: "18px",
    "@media only screen and (max-width: 600px)": {
      gap: "5px",
    },
  },
  comparisionContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    marginTop: "60px",
  },
  comparisionTable: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    gap: "30px",
    width: "25%",
    position: "sticky",
    top: "80px",
    "@media only screen and (max-width: 600px)": {
      display: "none",
    },
  },
  comparisionTableBody: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    gap: "20px",
    width: "100%",
  },
  tableOption: {
    position: "relative",
    padding: "0 20px",
    transition: "0.3s ease",
    cursor: "pointer",
    borderLeft: "2px solid transparent",
    "&.active": {
      borderLeft: "2px solid #F27335",
    },
    "&:hover": {
      borderLeft: "2px solid #F27335",
    },
  },
  comparisionTableContent: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    gap: "30px",
    width: "75%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  comparisionTableContentContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    gap: "20px",
    width: "100%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  comparisionContainerImage: {
    padding: "20px 70px",
    background: "#def6fe",
    borderRadius: "20px",
    "& img": {
      width: "100%",
    },
    "@media only screen and (max-width: 600px)": {
      "& img": {
        width: "100% !important",
      },
    },
  },
  comparisionContainerFooter: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
  },
  readMore: {
    cursor: "pointer",
    color: "#ff7a59",
    textDecoration: "underline",
  },
  readMorePrimary: {
    cursor: "pointer",
    color: "#02b1d0",
    textDecoration: "underline",
  },
  supportSystem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "30px",
  },
  supportToggler: {
    marginTop: "30px",
    display: "flex",
    alignItems: "center",
    gap: "30px",
    justifyContent: "flex-start",
    "@media only screen and (max-width: 600px)": {
      display: "none",
      width: "100%",
      gap: "10px",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  },
  toggler: {
    fontSize: "22px",
    padding: "15px",
    borderRadius: "100px",
    border: "1px solid #00b4d3",
    cursor: "pointer",
    transition: "0.3s ease",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#00b4d3",
      color: "white",
    },
    "&.active": {
      backgroundColor: "#00b4d3",
      color: "white",
    },
    "@media only screen and (max-width: 600px)": {
      minWidth: "max-content",
      padding: "10px",
      fontSize: "15px",
    },
  },
  mobileToggler: {
    fontSize: "15px",
    padding: "10px",
    borderRadius: "100px",
    border: "1px solid #00b4d3",
    cursor: "pointer",
    textAlign: "center",
    transition: "0.3s ease",
    width: "100%",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#00b4d3",
      color: "white",
    },
    "&.active": {
      backgroundColor: "#00b4d3",
      color: "white",
    },
  },
  supportSystemMobileContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "20px",
    width: "100%",
  },

  supportSystemContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "50px",
    marginTop: "30px",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      justifyContent: "flex-start",
      gap: "20px",
      width: "100%",
    },
    "@media only screen and (min-width:768px) and (max-width:990px)": {
      gap: "15px",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
  },
  supportList: {
    marginTop: "30px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "30px",
    flexDirection: "column",
  },
  supportListItem: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "10px",
  },
  supportImageContainer: {
    width: "35%",
    "& img": {
      width: "100%",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      "& img": {
        width: "100%",
      },
    },
    "@media only screen and (min-width:768px) and (max-width:990px)": {
      width: "48%",
    },
  },
  supportSystemDetails: {
    width: "50%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  mobileSupportSystem: {
    display: "none",
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "20px",
    "@media only screen and (max-width: 600px)": {
      display: "flex",
    },
  },
  detailContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  reverse: {
    flexDirection: "row-reverse",
  },
  detailLeftContainer: {
    display: "flex",
    width: "50%",
    gap: "18px",
    alignItems: "baseline",
    justifyContent: "flex-start",
    flexDirection: "row",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  detailsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "15px",
  },
  pointer: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "flex-start",
    gap: "30px",
    width: "100%",
    "& img": {
      width: "15px",
    },
  },
  toolsSection: {
    background: "#f1ffee",
    "&:nth-child(even)": {
      background: "#fff6ed",
      margin: "20px 0",
    },
  },
  mobVisibleImage: {
    display: "none",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      display: "block",
    },
  },
  detailRightContainer: {
    width: "50%",
    position: "relative",
    "& img": {
      width: "100%",
    },
    "& .absoluteRightImage": {
      width: "100%",
      position: "absolute",
      right: "0%",
      top: "50%",
      transform: "translate(10%, -50%)",
    },
    "& .absoluteLeftImage": {
      width: "100%",
      position: "absolute",
      left: "0%",
      top: "50%",
      transform: "translate(-20%, -50%)",
    },
    "@media only screen and (max-width: 600px)": {
      display: "none",
    },
    "@media only screen and (min-width:768px) and (max-width:990px)": {
      "& .absoluteRightImage": {
        right: "10%",
      },
    },
  },
  cardsContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginTop: "35px",
    rowGap: "15px",
  },
  card: {
    width: "24%",
    backgroundImage: "linear-gradient(to bottom, #d5f1ff, #dcfdff);",
    padding: "15px 20px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "20px",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  cardImage: {
    maxWidth: "26px",
    width: "auto",
  },
  buttonGroup: {
    display: "flex",
    width: "80%",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
    "& .primaryButton": {
      minWidth: "49%",
    },
    "& .primaryButtonOutline": {
      minWidth: "49%",
    },
    "@media only screen and (max-width: 600px)": {
      width: "90%",
    },
    "@media only screen and (min-width:768px) and (max-width:990px)": {
      width: "100%",
    },
  },
  highlightSection: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginTop: "50px",
    rowGap: "20px",
  },
  highlightCard: {
    width: "49%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "30px",
    "& img": {
      marginTop: "5px",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  hightlightRightContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "5px",
  },
  salesPipelineImageContainer: {
    width: "70%",
    margin: "60px auto 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  salesPipeline: {
    width: "100%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  tableContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  tableHeader: {
    width: "100%",
    background: "#16A4BE",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  tableBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "5px",
    width: "100%",
  },
  tableHeading: {
    padding: "30px 20px",
    color: "white",
    width: "33%",
  },
  tableRow: {
    width: "100%",
    display: "flex",
    background: "#F6FBFC",
    justifyContent: "flex-start",
    alignItems: "strech",
    "&:nth-child(even)": {
      background: "#E9F6F8",
    },
  },
  parameter: {
    padding: "30px 20px",
    width: "33%",
  },
  tableData: {
    padding: "30px 20px",
    width: "33%",
    "&.tableBorder": {
      borderLeft: "1px solid #D3DDDF",
      borderRight: "1px solid #D3DDDF",
    },
  },
  gradientSection: {
    backgroundImage: "linear-gradient(359deg, #fffbe4 40%, #dcfff7 70%)",
    backgroundSize: "cover",
    backgroundPosition: "top",
    backgroundRepeat: "no-repeat",
  },
  benifitSection: {},
  benifitSectionContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: "70px",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      marginTop: "30px",
    },
    // "@media only screen and (min-width:768px) and (max-width:990px)": {
    //   flexDirection: "column",
    //   marginTop: "30px",
    // }
  },
  benfitSectionLeft: {
    width: "50%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  benifitSectionRight: {
    width: "40%",
    "& img": {
      width: "100%",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  collapseableContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    paddingBottom: "20px",
    gap: "20px",
    marginBottom: "15px",
  },
  collapseableHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  collapseableBody: {},
  faqAnswer: {
    color: "white",
    fontSize: 18,
    marginTop: 8,
    marginBottom: 8,
    maxWidth: 920,
    width: "100%",
  },
  expendButton: {
    backgroundColor: "#eaf0f6",
    "&:hover": {
      background: "#a1dae2",
    },
  },
  testimonialContainer: {
    position: "relative",
    width: "80%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: "60px",
    backgroundImage: "linear-gradient(to bottom, #feffee, #fff)",
    backgroundPosition: "top",
    backgroundSize: "cover",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: "100px 100px  20px 100px",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      borderRadius: "20px",
      padding: "30px",
    },
    "@media only screen and (min-width:768px) and (max-width:990px)": {
      width: "100%",
    },
  },
  reviewBox: {
    display: "flex",
    margin: "auto",
    width: "98%",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "15px",
    marginTop: "30px",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column-reverse",
      textAlign: "center",
    },
  },
  footer: {
    marginTop: "10px",
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
    "@media only screen and (max-width: 600px)": {
      display: "none",
    },
  },
  secondaryFooter: {
    textAlign: "right",
    marginTop: "10px",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      display: "flex",
      width: "100%",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "20px",
    },
  },
  absoluteButton: {
    position: "absolute",
    top: "100%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "@media only screen and (max-width: 600px)": {
      position: "relative",
      top: "auto",
      marginTop: "20px",
      left: "auto",
      transform: "none",
      width: "100%",
      "& .primaryButton": {
        width: "100%",
      },
    },
  },
  faqTitle: {
    color: "rgb(46, 63, 79)",
    display: "flex",
    fontSize: 20,
    marginTop: 10,
    fontWeight: 600,
    marginBottom: 8,
    justifyContent: "space-between",
    alignItems: "center",
  },
  faqAnswer: {
    color: "rgb(46, 63, 79)",
    fontSize: 18,
    marginTop: 8,
    marginBottom: 8,
    maxWidth: 920,
    width: "calc( 100% - 50px )",
  },
  blueSection: {
    background: `url(${sectionBackground})`,
    backgroundSize: "cover",
    overflow: "hidden",
    backgroundPosition: "top",
    backgroundRepeat: "no-repeat",
  },
}));

const multichannelNew = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(0);
  const [faqOpen, setFaqOpen] = useState(0);
  const [activeTab, setActiveTab] = useState(1);
  const [supportSystemActive, setsupportSystemActive] = useState(0);
  const handleClickCollapse = (index) => {
    setOpen((res) => (res === index ? -1 : index));
  };
  const handleClickCollapseFaq = (index) => {
    setFaqOpen((res) => (res === index ? -1 : index));
  };
  const scrollToTop = (containerId) => {
    const container = document.getElementById(containerId);
    if (container) {
      const containerTop = container.getBoundingClientRect().top;
      const offsetPosition = containerTop + window.pageYOffset - 80;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };
  const keyPoints = [
    "Unified inbox",
    "Auto-lead capture",
    "AI-powered chatbot",
  ];
  const supportSystemListing = [
    {
      buttomText: "Call centres",
      image: supportSystemListingImage1,
      desc: "Call centres leverage Pepper Cloud CRM for its multichannel integration to streamline customer service operations. It unifies communication across phone, email, chat, and social media, ensuring consistent and efficient support.",
      points: [
        <>
          <span className="font-bold">Seamless interaction handling:</span>{" "}
          Manage calls, emails, and chats from one platform, reducing response
          times and improving customer satisfaction.
        </>,
        <>
          <span className="font-bold">Real-time customer insights:</span> Access
          comprehensive customer histories, allowing agents to provide
          personalised support and resolve issues faster.
        </>,
        <>
          <span className="font-bold">Automated follow-ups:</span> Send
          automated follow-up messages via preferred channels, ensuring no
          customer query is left unanswered.
        </>,
      ],
      altText: "Multichannel CRM for call centres",
    },
    {
      buttomText: "Manufacturing",
      image: supportSystemListingImage2,
      desc: "Manufacturing companies use Pepper Cloud CRM to improve customer relations and streamline processes. Multichannel integration ensures seamless communication between sales teams, suppliers, and customers.",
      points: [
        <>
          <span className="font-bold">Streamlined order management:</span>{" "}
          Coordinate orders and enquiries across multiple channels, ensuring
          timely responses and efficient processing.
        </>,
        <>
          <span className="font-bold">Enhanced supplier communication:</span>{" "}
          Maintain consistent communication with suppliers through integrated
          channels, improving supply chain management.
        </>,
        <>
          <span className="font-bold">Real-time production updates:</span>{" "}
          Provide customers with real-time updates on order status and
          production timelines, enhancing transparency and trust.
        </>,
      ],
      altText: "Multichannel CRM for healthcare industry",
    },
    {
      buttomText: "Healthcare",
      image: supportSystemListingImage3,
      desc: "The healthcare industry uses the multichannel integration features of the CRM to enhance patient care and streamline administrative processes. It consolidates communication across phone, email, and other communication channels, ensuring efficient and coordinated patient interactions.",
      points: [
        <>
          <span className="font-bold">Comprehensive patient management:</span>{" "}
          Manage appointments, enquiries, and medical records on one platform to
          cut administrative tasks and enhance the patient experience.
        </>,
        <>
          <span className="font-bold">Easily access patients' details:</span>{" "}
          Instantly access patient histories and preferences to provide
          personalised care and make informed decisions.
        </>,
        <>
          <span className="font-bold">Automated appointment reminders:</span>{" "}
          Send automated reminders for appointments and medications to reduce
          missed visits and enhance patient loyalty.
        </>,
      ],
      altText: "Multichannel CRM for manufacturing industry",
    },
    {
      buttomText: "Logistics",
      image: supportSystemListingImage4,
      desc: "Logistics companies adopt Pepper Cloud CRM to optimise operations and improve customer service. Multichannel integration enables efficient communication across the supply chain, ensuring timely deliveries and customer satisfaction.",
      points: [
        <>
          <span className="font-bold">Centralised communication hub:</span>{" "}
          Manage customer enquiries, shipping updates, and support requests from
          one platform, improving operational efficiency.
        </>,
        <>
          <span className="font-bold">Automated notifications:</span> Send
          automated shipment updates and delivery confirmations via preferred
          customer channels, reducing manual efforts.
        </>,
        <>
          <span className="font-bold">Real-time tracking:</span> Provide
          real-time tracking information to customers through integrated
          communication channels, enhancing transparency and trust.
        </>,
      ],
      altText: "Multichannel CRM for logistics",
    },
  ];
  const toolDetails = [
    {
      img: toolsImage1,
      heading: "Comprehensive CRM social media integration",
      desc: "Connect all your social media channels to Pepper Cloud CRM for a unified communication experience. Manage conversations from Facebook Messenger, WhatsApp, Instagram, Telegram, and more in one place, ensuring real-time synchronisation and efficient lead management.",
      points: [
        {
          boldText: "Send instant replies with message templates:",
          text: "Automate replies with pre-approved templates for instant and consistent communication across all channels.",
        },
        {
          boldText: "Automatic lead capture:",
          text: "Automatically capture lead data from Facebook, WhatsApp, Instagram, and Telegram, eliminating manual entry and ensuring no leads are missed.",
        },
        {
          boldText: "Broadcast messages:",
          text: "Send directly or schedule broadcast messages for large audiences with a single click, ensuring your promotions and updates reach everyone.",
        },
        {
          boldText: "Provide 24/7 support with AI chatbots:",
          text: "Offer round-the-clock support with AssitAI by automating responses to FAQs and routine customer enquiries.",
        },
      ],
      altText: "Message channel Integration",
      knowMore:
        "https://blog.peppercloud.com/channel-integration/?utm_source=Multichannel+integration&utm_medium=Know+more",
    },
    {
      img: toolsImage2,
      heading: "Dynamic calling tool integration",
      desc: "Enhance your communication with Pepper Cloud CRM’s seamless calling tool integration. Connect with Aircall or KrispCall for voice calling and Zoom for video conferencing, all within the CRM platform for a unified communication experience.",
      points: [
        {
          boldText: "Unified call management:",
          text: "Initiate outbound calls with Aircall or KrispCall integration and set up Zoom meetings with a single click, streamlining communication directly from the CRM.",
        },
        {
          boldText: "Comprehensive caller details:",
          text: "Access detailed caller information before connecting and integrate Zoom meeting details, ensuring personalised and informed interactions.",
        },
        {
          boldText: "Automatic logging and tracking:",
          text: "Automatically log call details and video meeting updates, including duration, recordings, and links, for a complete history in the CRM.",
        },
        {
          boldText: "Real-time analytics and smart routing:",
          text: "Analyse call and video conference metrics with real-time dashboards and use workflows to smartly route inbound calls and manage meetings.",
        },
      ],
      altText: "Cloud based calling Integration",
      knowMore:
        "http://blog.peppercloud.com/crm-with-cloud-based-calling-for-sales-team/?utm_source=Multichannel+integration&utm_medium=Know+more",
    },
    {
      img: toolsImage3,
      heading: "Powerful email inbox integration",
      desc: "Seamlessly integrate your Gmail and Outlook accounts with Pepper Cloud CRM, supported by secure SMTP configuration. Manage all your email communications on a single platform, boosting efficiency and ensuring you never miss an important message.",
      points: [
        {
          boldText: "Centralised email management:",
          text: "Link your Gmail and Outlook accounts and configure custom SMTP servers to send emails directly from the CRM.",
        },
        {
          boldText: "Unified communication view:",
          text: "Manage all your emails from multiple accounts directly within Pepper Cloud CRM, without switching between platforms.",
        },
        {
          boldText: "Streamlined email automation:",
          text: "Configure triggers to automate sending follow-ups and reminders, streamlining your email communication.",
        },
        {
          boldText: "Comprehensive customer insights:",
          text: "Gain complete visibility into customer interactions and preferences with our robust CRM email inbox integration.",
        },
      ],
      altText: "CRM email inbox Integration ",
      knowMore:
        "https://blog.peppercloud.com/email-integration-with-crm/?utm_source=Multichannel+integration&utm_medium=Know+more",
    },
    {
      img: toolsImage4,
      heading: "Effortless email marketing integration",
      desc: "Boost your sales outreach with CRM email marketing integration. Connect with leading email marketing tools like Mailerlite and Mailchimp to make your campaigns more targeted and effective, driving better results for your business.",
      points: [
        {
          boldText: "Customer segmentation:",
          text: "Segment customers by location, interests, and purchase history to ensure your emails target the right audience with relevant content.",
        },
        {
          boldText: "Personalised campaigns:",
          text: "Increase conversions by personalising your email campaigns, making each message feel engaging and relevant.",
        },
        {
          boldText: "Automated triggers:",
          text: "Automate email triggers based on customer actions for timely, relevant communication without manual effort.",
        },
        {
          boldText: "Comprehensive insights:",
          text: "Track delivery rates, open rates, and clicks with detailed campaign analytics. Use these insights to refine strategies and boost performance.",
        },
      ],
      altText: "CRM email marketing Integration",
      knowMore:
        "https://blog.peppercloud.com/email-marketing-with-crm-technology/?utm_source=Multichannel+integration&utm_medium=Know+more",
    },
  ];
  const featureList = [
    {
      title: "Auto-lead capture ",
      image: autoCaptureLeadsIcon,
    },
    {
      title: "Contact management",
      image: contactmanagementIcon,
    },
    {
      title: "Pipeline management",
      image: piplineIcon,
    },
    {
      title: "Omnichannel CRM",
      image: omniChannelIcon,
    },
    {
      title: "Email marketing",
      image: messageIcon,
    },
    {
      title: "Quotation generation",
      image: quotationIcon,
    },
    {
      title: "Sales forecasting",
      image: salesIcon,
    },
    {
      title: "Opportunity management",
      image: oppertunityIcon,
    },
    {
      title: "Workflow automation",
      image: whatsAppFlowIcon,
    },
    {
      title: "AI chatbot",
      image: aiChatIcon,
    },
    {
      title: "WhatsApp CRM",
      image: whatsappIcon,
    },
    {
      title: "Mobile CRM",
      image: mobileIcon,
    },
    {
      title: "UTM tracking",
      image: utmTrackingIcon,
    },
    {
      title: "Real-time dashboard",
      image: dashboardIcon,
    },
    {
      title: "VOIP tool integration",
      image: airCallIcon,
    },
    {
      title: "Video conferencing",
      image: videoCallIcon,
    },
  ];
  const highlights = [
    {
      heading: "Comprehensive multichannel support",
      content:
        "Pepper Cloud CRM seamlessly integrates WhatsApp, Facebook, Instagram, Telegram, Mailerlite, Mailchimp, Aircall, KrispCall, Zoom, Gmail, Outlook, and more into a single platform, streamlining all customer interactions for unparalleled efficiency.",
    },
    {
      heading: "Boosts customer engagement",
      content:
        "Automates lead capture and allows personalised messaging across multiple channels, driving higher customer satisfaction and conversion rates with timely communication.",
    },
    {
      heading: "Unified sales and marketing",
      content:
        "Combines sales and marketing efforts across channels, offering a cohesive view of interactions for more efficient management and targeted strategies.",
    },
    {
      heading: "Real-time analytics and insights",
      content:
        "Get a bird's-eye view of your call, campaign, and conversation metrics with an intuitive dashboard and get valuable insights into customer behaviour and preferences.",
    },
    {
      heading: "Streamlined collaboration",
      content:
        "Foster collaboration with shared access to customer data and interactions across all channels, ensuring your team works together seamlessly for optimal sales and support.",
    },
    {
      heading: "Optimised sales management",
      content:
        "Efficiently manage your sales pipeline by tracking leads, monitoring sales activities, automating tasks across multiple channels, and closing deals faster.",
    },
  ];
  const tableContent = [
    {
      heading: "Customer interaction",
      traditionalData:
        "Limited to email and phone, often leading to fragmented communication.",
      multichannelData:
        "Integrates email, phone, social media, chat, and more for cohesive communication.",
    },
    {
      heading: "Customer insights",
      traditionalData: "Provides basic insights with limited data sources.",
      multichannelData:
        "Offers comprehensive, real-time insights across all channels, enhancing decision-making.",
    },
    {
      heading: "Response time",
      traditionalData:
        "Generally slower due to limited channels and manual processes.",
      multichannelData:
        "Enables faster, real-time responses through automated, integrated systems.",
    },
    {
      heading: "Integration with other tools",
      traditionalData: "Limited capabilities leading to switching platforms.",
      multichannelData:
        "Extensive integrations with various platforms, reducing manual efforts.",
    },
    {
      heading: "Automation",
      traditionalData:
        "Basic automation features, mainly for repetitive tasks.",
      multichannelData:
        "Advanced, AI-driven automation for personalised customer interactions.",
    },
    {
      heading: "Scalability",
      traditionalData:
        "Less scalable, struggles to adapt to growing business needs.",
      multichannelData:
        "Highly scalable, easily accommodates business growth and increased interactions.",
    },
    {
      heading: "Customer journey mapping",
      traditionalData: "Incomplete view, often missing critical touchpoints.",
      multichannelData:
        "Provides a complete view of the customer journey, enhancing personalisation.",
    },
    {
      heading: "Data management",
      traditionalData: "Siloed data, leading to incomplete customer profiles.",
      multichannelData:
        "Centralised and unified data management, offering a holistic customer view.",
    },
  ];
  const benfitAndAdvantages = [
    {
      image: benifitImage1,
      title: "360-degree customer insights",
      description:
        "Gain a comprehensive view of each customer by integrating all interactions from various channels, enhancing profile accuracy, and understanding of preferences.",
    },
    {
      image: benifitImage1,
      title: "Enhances customer experience",
      description:
        "Improve customer satisfaction with quick and diverse contact options, reducing wait times, and increasing availability.",
    },
    {
      image: benifitImage1,
      title: "Real-time agent responses",
      description:
        "Empower agents to respond promptly across channels, boosting positive feedback and enhancing customer retention.",
    },
    {
      image: benifitImage1,
      title: "Consistent multichannel service",
      description:
        "Provide uniform service and messaging across all channels, ensuring efficient support and targeted sales leads.",
    },
  ];
  const faq = [
    {
      question: "What is a multi-channel CRM system?",
      answer:
        "A multi-channel CRM system is a platform that integrates multiple communication channels, such as email, social media, SMS, and more, to provide a unified view of customer interactions and enhance customer engagement.",
    },
    {
      question: "Multichannel vs. omnichannel: What is the difference?",
      answer:
        "Multichannel CRM refers to managing customer interactions across various channels independently, while omnichannel CRM integrates these channels to provide a seamless and consistent customer experience across all touchpoints.",
    },
    {
      question: "How does multichannel integration benefit my business?",
      answer:
        "Multichannel integration enhances customer engagement, improves data consistency, streamlines marketing efforts, and provides comprehensive insights into customer behaviour, ultimately driving sales growth.",
    },
    {
      question: "Can Pepper Cloud CRM handle multiple communication channels?",
      answer:
        "Yes, Pepper Cloud CRM is designed to handle multiple communication channels, ensuring seamless integration and efficient management of customer interactions across email, social media, calling, and more.",
    },
    {
      question:
        "How does multichannel marketing improve the customer experience?",
      answer:
        "Multichannel marketing provides a consistent and personalised customer experience by ensuring that customers receive relevant messages through their preferred communication channels, enhancing satisfaction and loyalty.",
    },
    {
      question: "Is multichannel integration difficult to set up?",
      answer: (
        <>
          With Pepper Cloud CRM, setting up multichannel integration is
          straightforward. Our platform is designed for ease of use, with
          intuitive setup processes and comprehensive support.{" "}
          <a href="https://peppercloud.com/contact">Contact our support team</a>{" "}
          for more information.
        </>
      ),
    },
    {
      question: "How can a CRM with multichannel integration boost sales?",
      answer:
        "By providing a unified view of customer interactions and enabling targeted marketing campaigns, a CRM with multichannel integration can help businesses engage effectively with customers, leading to increased conversions and sales.",
    },
    {
      question: "What channels can I integrate with Pepper Cloud CRM?",
      answer:
        "In Pepper Cloud CRM, you can integrate various channels, including WhatsApp, Facebook Messenger, Telegram, Instagram, MailerLite, Mailchimp, Aircall, Zoom, Gmail, Outlook, and more, allowing you to manage all customer interactions from a single platform.",
    },
    {
      question: "Does multichannel marketing require a large team?",
      answer:
        "Not necessarily. With the automation and efficiency provided by Pepper Cloud CRM, even small teams can effectively manage multichannel marketing campaigns.",
    },
    {
      question:
        "How does multichannel integration help with customer retention?",
      answer:
        "Multichannel integration helps in customer retention by providing personalised and consistent communication, addressing customer needs promptly, and fostering strong relationships through various touchpoints.",
    },
  ];

  return (
    <main>
      <SEO
        canonical="https://peppercloud.com/sales-crm-features/multichannel-integration-for-sales-marketing"
        description="Get unified CRM tool with multichannel integration for all your sales marketing and customer support needs. Comes with popular messaging app integrations: WhatsApp, Facebook, Instagram, Telegram and more."
        keywords="multi channel integration, advantages of multichannel marketing, multi channel marketing"
        pathname="https://peppercloud.com/sales-crm-features/multichannel-integration-for-sales-marketing"
        title="Best CRM with Multichannel Integration | Pepper Cloud"
      />
      <section className={classes.heroSection}>
        <div className="container">
          <div className={classes.heroSectionInner}>
            <motion.h1
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className="font-48 font-bold"
            >
              Multiply your sales effortlessly with{" "}
              <span className={classes.highlight}>
                multichannel integration
              </span>{" "}
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className="font-20 "
            >
              Effortlessly sync all your communication channels in real-time
              with Pepper Cloud CRM. The multichannel integration keeps your
              conversations seamlessly connected, eliminating the hassle of
              juggling multiple platforms.
            </motion.p>
            <div
              className={classes.list}
              aria-label="Best CRM Software Singapore"
            >
              {keyPoints.map((point, index) => (
                <motion.div
                  className={classes.listItem}
                  key={index}
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  viewport={{ once: true }}
                  aria-label={`Feature: ${point}`}
                >
                  <img src={greenCheckIcon} alt="Check Mark" />
                  <p className="font-20 ">{point}</p>
                </motion.div>
              ))}
            </div>
            <motion.a
              href="https://peppercloud.com/contact?utm_source=Multichannel+integration&utm_medium=Book+a+demo"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className="primaryButton font-24 mob-w-100"
              aria-label="Best CRM Software Singapore"
            >
              Book a demo
            </motion.a>
          </div>
        </div>
      </section>
      <section>
        <div className="container ">
          <motion.div
            className="header"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            aria-label="Heading: Benefits of using all-in-one CRM software in Singapore"
          >
            <h2 className="font-48 font-extrabold">
              Connect all your communication channels in one place
            </h2>
          </motion.div>
        </div>
      </section>
      {toolDetails.map((tools, index) => (
        <section className={classes.toolsSection}>
          <div className="container">
            <div
              className={clsx(
                classes.detailContainer,
                index % 2 !== 0 && classes.reverse
              )}
            >
              <div className={classes.detailLeftContainer}>
                <div className={classes.imageContainer}>
                  <img src={triangleArrow} className="" alt="triangleArrow" />
                </div>
                <div className={classes.detailsContainer}>
                  <motion.h3
                    initial={{ opacity: 0, x: -30 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    viewport={{ once: true }}
                    className="font-bold font-24"
                  >
                    {tools.heading}
                  </motion.h3>
                  <motion.p
                    initial={{ opacity: 0, x: -30 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    viewport={{ once: true }}
                    className=" font-20"
                  >
                    {tools.desc}
                  </motion.p>
                  {tools.points.map((pointer, index) => (
                    <motion.div
                      whileInView={{ opacity: [0, 1], y: [50, 0] }}
                      transition={{ duration: 0.5, delay: index * 0.1 }}
                      viewport={{ once: true }}
                      className={classes.pointer}
                    >
                      <img src={greenTickIcon} alt="greenTick" />
                      <p className="font-20 ">
                        <span className="font-20 font-bold">
                          {pointer.boldText}
                        </span>{" "}
                        {pointer.text}
                      </p>
                    </motion.div>
                  ))}
                  <motion.img
                    initial={{ opacity: 0, y: 30 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                    viewport={{ once: true }}
                    className={classes.mobVisibleImage}
                    src={tools.img}
                  />

                  <motion.div
                    initial={{ opacity: 0, y: 30 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                    viewport={{ once: true }}
                    className={classes.buttonGroup}
                  >
                    <a
                      className="primaryButtonOutline font-bold font-20"
                      target="_blank"
                      href={tools.knowMore}
                    >
                      Know more
                    </a>
                    <a
                      className="primaryButton font-bold font-20"
                      href="https://peppercloud.com/contact?utm_source=Multichannel+integration&utm_medium=Book+a+demo"
                    >
                      Book a demo
                    </a>
                  </motion.div>
                </div>
              </div>
              <div className={clsx(classes.detailRightContainer)}>
                <img
                  src={tools.img}
                  alt={tools.altText}
                  className={
                    index % 2 === 0
                      ? "absoluteRightImage "
                      : "absoluteLeftImage"
                  }
                />
              </div>
            </div>
          </div>
        </section>
      ))}
      <section className={classes.benifitSection}>
        <div className="container">
          <motion.div
            className="header"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            aria-label="Heading: Benefits of using all-in-one CRM software in Singapore"
          >
            <h2 className="font-48 font-extrabold">
              Benefits and advantages of multichannel marketing
            </h2>
          </motion.div>
          <div className={classes.benifitSectionContainer}>
            <div className={classes.benfitSectionLeft}>
              {benfitAndAdvantages.map((benfitAndAdvantage, index) => (
                <motion.div
                  className={classes.collapseableContainer}
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  viewport={{ once: true }}
                >
                  <div
                    className={classes.collapseableHeader}
                    onClick={() => handleClickCollapse(index)}
                  >
                    <p className="font-semibold font-20">
                      {benfitAndAdvantage.title}
                    </p>
                    <IconButton
                      aria-label="Expand or Collapse"
                      className={classes.expendButton}
                      color="default"
                    >
                      {index !== open ? (
                        <AddIcon fontSize="small" />
                      ) : (
                        <RemoveIcon fontSize="small" />
                      )}
                    </IconButton>
                  </div>
                  {/* <div className={classes.collapseableBody} id={`collapseableBody${index}`}>
                      <p className=" font-18">{benfitAndAdvantage.description}</p>
                    </div> */}
                  <Collapse in={open === index} timeout="auto" unmountOnExit>
                    <p className={" font-18"} fontSize={16}>
                      {benfitAndAdvantage.description}
                    </p>
                  </Collapse>
                </motion.div>
              ))}
            </div>
            <div className={classes.benifitSectionRight}>
              <motion.img
                initial={{ opacity: 0, x: 30 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                src={benifitImage1}
                alt="Benefits of multichannel marketing"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="border">
        <div className="container">
          <motion.div
            className="header"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            <h2 className="font-40 font-extrabold">
              Trusted CRM solution for businesses across multiple sectors
            </h2>
          </motion.div>
          <div className="mt40">
            <CompanyCard className={classes.cimg} />
          </div>
        </div>
      </section>
      <section
        className="border"
        aria-label="Best CRM System for SMEs in Singapore"
      >
        <div className="container">
          <motion.div
            className="header"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            aria-label="Best CRM System for SMEs in Singapore"
          >
            <h2
              className="font-48 font-extrabold"
              aria-label="Best CRM System for SMEs in Singapore"
            >
              Application and use cases: <br /> CRM with multichannel
              integration
            </h2>
          </motion.div>
          <div
            className={classes.supportSystem}
            aria-label="Industry-specific CRM solutions from Pepper Cloud"
          >
            <div
              className={classes.supportToggler}
              aria-label="Industry-specific CRM solutions from Pepper Cloud"
            >
              <div
                className={clsx(
                  "font-24",
                  "font-bold",
                  supportSystemActive === 0 ? "active" : "",
                  classes.toggler
                )}
                onClick={() => setsupportSystemActive(0)}
                aria-label="CRM Software for Beauty Salon"
              >
                Call centres
              </div>

              <div
                className={clsx(
                  "font-24",
                  "font-bold",
                  supportSystemActive === 1 ? "active" : "",
                  classes.toggler
                )}
                onClick={() => setsupportSystemActive(1)}
                aria-label="CRM Software for food delivery"
              >
                Manufacturing
              </div>

              <div
                className={clsx(
                  "font-24",
                  "font-bold",
                  supportSystemActive === 2 ? "active" : "",
                  classes.toggler
                )}
                onClick={() => setsupportSystemActive(2)}
                aria-label="CRM Software for Travel Agency"
              >
                Healthcare
              </div>

              <div
                className={clsx(
                  "font-24",
                  "font-bold",
                  supportSystemActive === 3 ? "active" : "",
                  classes.toggler
                )}
                onClick={() => setsupportSystemActive(3)}
                aria-label="CRM Software for Real Estate"
              >
                Logistics
              </div>
            </div>
          </div>

          <div className={clsx(classes.supportSystemContainer, "mob-hide")}>
            {supportSystemListing.map(
              (support, index) =>
                index === supportSystemActive && (
                  <motion.div
                    className={classes.supportImageContainer}
                    initial={{ opacity: 0, x: -30 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    viewport={{ once: true }}
                    // aria-label={support.altText}
                  >
                    <img src={support.image} alt={support.altText} />
                  </motion.div>
                )
            )}
            {supportSystemListing.map(
              (listingItem, index) =>
                supportSystemActive === index && (
                  <motion.div
                    className={classes.supportSystemDetails}
                    initial={{ opacity: 0, x: 30 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    viewport={{ once: true }}
                    key={index}
                    id={index}
                    aria-label={listingItem.altText}
                  >
                    <p className="font-18 " aria-label={listingItem.altText}>
                      {listingItem.desc}
                    </p>
                    <div
                      className={classes.supportList}
                      aria-label="Key features of Pepper Cloud CRM for real estate businesses in Singapore"
                    >
                      {listingItem.points.map((point, index) => (
                        <div
                          className={classes.supportListItem}
                          aria-label="Feature: Interactive WhatsApp forms for property discovery"
                        >
                          <img
                            src={greenCheckOutline}
                            alt={listingItem.altText}
                          />
                          <p className="font-18 ">{point}</p>
                        </div>
                      ))}
                    </div>
                    {/* {index === 3 && (
                      <a
                        href="https://peppercloud.com/crm-case-studies?utm_source=crm+system+singapore&utm_medium=Read+customer+stories"
                        className="primaryButton font-24 mt40 font-bold"
                        aria-label="Read customer stories about Pepper Cloud CRM for real estate businesses in Singapore"
                      >
                        Read customer stories
                      </a>
                    )} */}
                  </motion.div>
                )
            )}
          </div>
          <div className={clsx(classes.supportSystemContainer, "mob-visible")}>
            {supportSystemListing.map((listingItem, index) => (
              <div className={classes.mobileSupportSystem}>
                <div
                  className={clsx(
                    "font-bold",
                    supportSystemActive === index ? "active" : "",
                    classes.mobileToggler
                  )}
                  onClick={() => setsupportSystemActive(index)}
                >
                  {listingItem.buttomText}
                </div>
                {supportSystemActive === index && (
                  <div className={classes.supportSystemMobileContent}>
                    <motion.div
                      className={classes.supportImageContainer}
                      initial={{ opacity: 0, x: -30 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 1 }}
                      viewport={{ once: true }}
                      // aria-label={support.altText}
                    >
                      <img src={listingItem.image} alt={listingItem.altText} />
                    </motion.div>
                    <motion.div
                      className={classes.supportSystemDetails}
                      initial={{ opacity: 0, x: 30 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 1 }}
                      viewport={{ once: true }}
                      key={index}
                      id={index}
                      aria-label={listingItem.altText}
                    >
                      <p className="font-18 " aria-label={listingItem.altText}>
                        {listingItem.desc}
                      </p>
                      <div
                        className={classes.supportList}
                        aria-label="Key features of Pepper Cloud CRM for real estate businesses in Singapore"
                      >
                        {listingItem.points.map((point, index) => (
                          <div
                            className={classes.supportListItem}
                            aria-label="Feature: Interactive WhatsApp forms for property discovery"
                          >
                            <img
                              src={greenCheckOutline}
                              alt={listingItem.altText}
                            />
                            <p className="font-18 ">{point}</p>
                          </div>
                        ))}
                      </div>
                      {index === 3 && (
                        <a
                          href="https://peppercloud.com/crm-case-studies?utm_source=crm+system+singapore&utm_medium=Read+customer+stories"
                          className="primaryButton font-24 mt40 mob-w-100 font-bold"
                          aria-label="Read customer stories about Pepper Cloud CRM for real estate businesses in Singapore"
                        >
                          Read customer stories
                        </a>
                      )}
                    </motion.div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
      <section
        className="border"
        aria-label="Pepper Cloud CRM features tailored for success"
      >
        <div className="container">
          <motion.div
            className="header"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            aria-label="Heading: Pepper Cloud CRM features tailored for business success"
          >
            <h2 className="font-48 font-extrabold">
              Pepper Cloud CRM features tailored for business success
            </h2>
          </motion.div>
          <div className={classes.cardsContainer}>
            {featureList.map((feature, index) => (
              <motion.div
                className={classes.card}
                key={index}
                whileInView={{ opacity: [0, 1], y: [50, 0] }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
                aria-label={`Pepper Cloud CRM feature: ${feature.title}`}
              >
                <img
                  src={feature.image}
                  className={classes.cardImage}
                  alt={`Pepper Cloud CRM feature: ${feature.title}`}
                />
                <p className="font-18 font-bold">{feature.title}</p>
              </motion.div>
            ))}
            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <motion.a
                href="https://peppercloud.com/contact?utm_source=crm+system+singapore&utm_medium=Book+a+free+demo"
                className="primaryButton font-24 mt60 mob-w-100"
                whileInView={{ opacity: [0, 1], y: [50, 0] }}
                viewport={{ once: true }}
                transition={{ duration: 1, delay: featureList.length * 0.1 }}
                aria-label="Explore all features of Pepper Cloud CRM for business success"
              >
                Book a free demo
              </motion.a>
            </div> */}
          </div>
        </div>
      </section>
      <section
        className={clsx(classes.gradientSection)}
        aria-label="Multichannel Marketing"
      >
        <div className="container">
          <motion.div
            className="header"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            aria-label="Multichannel Marketing"
          >
            <h2 className="font-48 font-extrabold">
              How is Pepper Cloud the best CRM with multichannel integration?
            </h2>
          </motion.div>
          <div
            className={classes.highlightSection}
            aria-label="Multichannel Marketing"
          >
            {highlights.map((highlight, index) => (
              <motion.div
                className={classes.highlightCard}
                key={index}
                whileInView={{ opacity: [0, 1], y: [50, 0] }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
                aria-label="Multichannel Marketing"
              >
                <img className={""} src={greenCheckIcon} alt="Check Mark" />
                <div className={classes.hightlightRightContainer}>
                  <p className="font-20 font-bold">{highlight.heading}</p>
                  <p className="font-20 ">{highlight.content}</p>
                </div>
              </motion.div>
            ))}
          </div>
          <motion.div
            initial={{ opacity: 0, y: -30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className={classes.salesPipelineImageContainer}
            aria-label="Image: Multichannel Marketing"
          >
            <img
              src={cloudImage}
              className={classes.salesPipeline}
              alt="Multichannel Marketing"
            />
          </motion.div>
        </div>
      </section>
      <section>
        <div className="container">
          <motion.div
            className="header"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            <h2 className="font-48 font-extrabold">
              Traditional CRM vs multichannel CRM
            </h2>
          </motion.div>
          <div className={clsx(classes.tableContainer, "mt60")}>
            <div className={classes.tableHeader}>
              <div
                className={clsx(classes.tableHeading, "font-bold", "font-20")}
              >
                Parameter
              </div>
              <div
                className={clsx(classes.tableHeading, "font-bold", "font-20")}
              >
                Traditional CRM
              </div>
              <div
                className={clsx(classes.tableHeading, "font-bold", "font-20")}
              >
                Multichannel CRM
              </div>
            </div>
            <div className={classes.tableBody}>
              {tableContent.map((content, index) => (
                <motion.div
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  viewport={{ once: true }}
                  className={classes.tableRow}
                >
                  <div
                    className={clsx(classes.parameter, "font-bold", "font-20")}
                  >
                    {content.heading}
                  </div>
                  <div
                    className={clsx(
                      classes.tableData,
                      "",
                      "font-20",
                      "tableBorder"
                    )}
                  >
                    {content.traditionalData}
                  </div>
                  <div className={clsx(classes.tableData, "", "font-20")}>
                    {content.multichannelData}
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="border">
        <div className="container">
          <motion.div
            className="header"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            <h2 className="font-48 font-extrabold">
              5 Best CRM for multichannel marketing in 2024
            </h2>
          </motion.div>
          <div className={classes.comparisionContainer}>
            <div className={classes.comparisionTable}>
              <div
                className={clsx(
                  classes.comparisionTableHeader,
                  "font-22",
                  "font-bold"
                )}
              >
                Table of Content
              </div>
              <div className={classes.comparisionTableBody}>
                <div
                  className={clsx(
                    classes.tableOption,
                    "font-22",
                    activeTab === 1 ? "active" : ""
                  )}
                  onClick={() => {
                    scrollToTop("container1");
                    setActiveTab(1);
                  }}
                >
                  1. Pepper Cloud
                </div>
                <div
                  className={clsx(
                    classes.tableOption,
                    "font-22",
                    activeTab === 2 ? "active" : ""
                  )}
                  onClick={() => {
                    scrollToTop("container2");
                    setActiveTab(2);
                  }}
                >
                  2. Zoho CRM
                </div>
                <div
                  className={clsx(
                    classes.tableOption,
                    "font-22",
                    activeTab === 3 ? "active" : ""
                  )}
                  onClick={() => {
                    scrollToTop("container3");
                    setActiveTab(3);
                  }}
                >
                  3. EngageBay CRM
                </div>
                <div
                  className={clsx(
                    classes.tableOption,
                    "font-22",
                    activeTab === 4 ? "active" : ""
                  )}
                  onClick={() => {
                    scrollToTop("container4");
                    setActiveTab(4);
                  }}
                >
                  4. Freshsales CRM
                </div>
                <div
                  className={clsx(
                    classes.tableOption,
                    "font-22",
                    activeTab === 5 ? "active" : ""
                  )}
                  onClick={() => {
                    scrollToTop("container5");
                    setActiveTab(5);
                  }}
                >
                  5. Monday CRM
                </div>
              </div>
            </div>
            <div className={classes.comparisionTableContent}>
              <div
                className={classes.comparisionTableContentContainer}
                id="container1"
              >
                <motion.h3
                  className="font-18 font-bold"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                  aria-label="Best CRM with multichannel integration"
                >
                  1. Pepper Cloud CRM
                </motion.h3>

                <motion.p
                  className="font-18 "
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  Pepper Cloud CRM stands out as the ultimate solution for
                  multichannel marketing in 2024. It seamlessly integrates
                  various communication channels to provide a unified platform
                  for managing customer interactions and enhancing marketing
                  strategies.
                </motion.p>

                <motion.p
                  className="font-18 "
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Multichannel integration:</b>{" "}
                  Effortlessly manage customer interactions across email, social
                  media, calling, and more, ensuring a consistent and engaging
                  experience.
                </motion.p>
                <motion.p
                  className="font-18 "
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Sales automation:</b> Streamline your
                  sales processes with advanced automation features, helping
                  your team close deals faster and more efficiently.
                </motion.p>
                <motion.p
                  className="font-18 "
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Analytics and reporting:</b> Gain
                  actionable insights with comprehensive analytics and reporting
                  tools, driving data-informed decisions for better marketing
                  outcomes.
                </motion.p>
                <motion.p
                  className="font-18 "
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Mobile CRM: </b> Stay connected on
                  the go with a powerful mobile CRM solution, ensuring your team
                  can manage customer communication anytime, anywhere.
                </motion.p>
                <motion.p
                  className="font-18 "
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Customisable workflows:</b> Tailor
                  workflows to suit your business needs, enhancing productivity
                  and ensuring a seamless customer journey.
                </motion.p>
                <motion.p
                  className="font-18 "
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  Pepper Cloud CRM is the ideal choice for small businesses due
                  to its affordability and ease of use, allowing teams to
                  quickly adapt and leverage its powerful features. Its scalable
                  solutions grow with your business, ensuring long-term value
                  and efficiency.
                </motion.p>

                <div className={classes.comparisionContainerFooter}>
                  <motion.a
                    href="https://peppercloud.com/contact?utm_source=Multichannel+integration&utm_medium=Book+a+demo"
                    className="primaryButton font-24 mob-w-100"
                    whileInView={{ opacity: [0, 1], y: [50, 0] }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.3 }}
                  >
                    Book a demo
                  </motion.a>

                  {/* <a
                  href="/"
                  className={clsx(
                    classes.readMore,
                    "font-18 font-bold font-orange"
                  )}
                >
                  Read more
                </a> */}
                </div>
                <motion.div
                  className={classes.comparisionContainerImage}
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <img
                    // style={{ width: "auto" }}
                    src={pcHomepage}
                    alt="Best CRM with multichannel integration"
                  />
                </motion.div>
              </div>
              <div
                className={classes.comparisionTableContentContainer}
                id="container2"
              >
                <motion.h3
                  className="font-18 font-bold"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                  aria-label="Zoho CRM"
                >
                  2. Zoho CRM
                </motion.h3>

                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  Zoho CRM offers multichannel marketing capabilities, allowing
                  businesses to engage with customers across various platforms.
                </motion.p>

                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Email marketing: </b>Execute
                  effective email campaigns to nurture leads and build customer
                  relationships.
                </motion.p>
                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Social media integration: </b>Connect
                  with customers on social media channels for enhanced
                  engagement and brand visibility.
                </motion.p>
                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Lead management: </b>Manage leads
                  from multiple channels, ensuring no opportunity is missed.
                </motion.p>
                <motion.div
                  className={classes.comparisionContainerImage}
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <img src={zohoImage} alt="Zoho CRM" />
                </motion.div>
              </div>
              <div
                className={classes.comparisionTableContentContainer}
                id="container3"
              >
                <motion.h3
                  className="font-18 font-bold"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                  aria-label="EngageBay CRM"
                >
                  3. EngageBay CRM
                </motion.h3>

                <motion.p
                  className="font-18 "
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  EngageBay CRM provides a comprehensive suite of tools for
                  multichannel marketing, helping businesses streamline their
                  customer interactions.
                </motion.p>

                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Multichannel communication:</b>Engage
                  with customers via email, chat, social media, and SMS from a
                  single platform.
                </motion.p>
                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Segmentation and targeting: </b>
                  Segment your audience for more personalised and targeted
                  marketing efforts.
                </motion.p>
                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Lead scoring:</b>Identify and
                  prioritise high-quality leads to optimise sales efforts.
                </motion.p>
                <motion.div
                  className={classes.comparisionContainerImage}
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <img src={engageBayImage} alt="EngageBay CRM" />
                </motion.div>
              </div>
              <div
                className={classes.comparisionTableContentContainer}
                id="container4"
              >
                <motion.h3
                  className="font-18 font-bold"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                  aria-label="Freshsales CRM"
                >
                  4. Freshsales CRM
                </motion.h3>

                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  Freshsales CRM is designed to support multichannel marketing
                  initiatives, offering a range of features to enhance customer
                  engagement.
                </motion.p>

                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Multichannel communication:</b>{" "}
                  Connect with customers through email, phone, chat, and more
                  for a seamless experience.
                </motion.p>
                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Custom sales pipelines: </b>Create
                  custom sales pipelines to match your unique sales processes.
                </motion.p>
                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Workflow automation: </b> Automate
                  workflows to boost productivity and streamline operations.
                </motion.p>
                <motion.div
                  className={classes.comparisionContainerImage}
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <img src={freshsalesCrmImage} alt="Freshsales CRM" />
                </motion.div>
              </div>
              <div
                className={classes.comparisionTableContentContainer}
                id="container5"
              >
                <motion.h3
                  className="font-18 font-bold"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                  aria-label="Monday CRM"
                >
                  5.Monday CRM
                </motion.h3>

                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  Monday CRM provides a versatile platform for multichannel
                  marketing, enabling businesses to manage customer interactions
                  effectively.
                </motion.p>

                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Unified platform: </b>Manage all
                  customer interactions from a single platform for better
                  coordination and efficiency.
                </motion.p>
                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Customisable dashboards:</b>Create
                  personalised dashboards to track and analyse key metrics.
                </motion.p>
                <motion.p
                  className="font-18"
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <b className="font-bold">Integration capabilities: </b>
                  Integrate with various tools and platforms to extend the
                  functionality of your CRM.
                </motion.p>
                <motion.div
                  className={classes.comparisionContainerImage}
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <img src={mondayCrmImage} alt="Monday CRM" />
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="container">
          <div className={classes.testimonialContainer}>
            <motion.div
              className="header"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              aria-label="Heading: Benefits of using all-in-one CRM software in Singapore"
            >
              <h2 className="font-48 font-extrabold">
                Our empowered customers are transforming their sales
              </h2>
            </motion.div>
            <div className={classes.reviewContainer}>
              <div className={classes.reviewBox}>
                <img
                  src={reviewLogo}
                  alt="Pepper Cloud CRM testimonial - 707-inc "
                />
                <p className="font-20">
                  Implementing Pepper Cloud CRM was seamless with their helpful
                  onboarding and training resources. It's our perfect CRM
                  solution, meeting all our needs (such as tracking sales,
                  automating workflows, and integrating with various platforms)
                  and offering proactive round-the-clock support. The WhatsApp
                  CRM also helped us streamline customer conversations and
                  enable seamless interaction tracking, ultimately leading to
                  increased productivity and efficiency.
                </p>
              </div>
              {/* <div className={classes.footer}>
                <a
                href="../crm-testimonials"
                  className={clsx(
                    classes.readMorePrimary,
                    "font-20",
                    "font-bold"
                  )}
                >
                  Read more
                </a>
              </div> */}
              <div className={classes.secondaryFooter}>
                <p className="font-20 font-bold">Joshua Kiong</p>
                <p className="font-20">
                  Manager (Advisor), 707-inc Great Excursion Travel Pte Ltd
                </p>
              </div>
            </div>
            <div className={classes.absoluteButton}>
              <a
                className="primaryButton font-20"
                href="https://peppercloud.com/crm-testimonials?utm_source=Multichannel+integration&utm_medium=Read+client+testimonials"
              >
                Read client testimonials
              </a>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <motion.div
            className="header"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            aria-label="Heading: Benefits of using all-in-one CRM software in Singapore"
          >
            <h2 className="font-48 font-extrabold">
              Frequently asked questions - CRM with multichannel integration
            </h2>
          </motion.div>
          {faq.map((content, index) => (
            <motion.div
              whileInView={{ opacity: [0, 1], y: [50, 0] }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              viewport={{ once: true }}
              key={content.question}
            >
              <Box
                className={classes.faqTitle}
                m={0}
                onClick={() => handleClickCollapseFaq(index)}
              >
                <Box component="h3" fontSize={18} my={2}>
                  {content.question}
                </Box>
                <div>
                  <IconButton
                    aria-label="Expand or Collapse"
                    className={classes.expendButton}
                    color="default"
                  >
                    {index !== open ? (
                      <AddIcon fontSize="small" />
                    ) : (
                      <RemoveIcon fontSize="small" />
                    )}
                  </IconButton>
                </div>
              </Box>
              <Collapse in={faqOpen === index} timeout="auto" unmountOnExit>
                <p className={classes.faqAnswer} fontSize={16}>
                  {content.answer}
                </p>
              </Collapse>
              <Divider />
            </motion.div>
          ))}
        </div>
      </section>
      <section className={clsx(classes.blueSection, "border")}>
        <div className="container">
          <motion.div
            className="header mb60"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            <h2 className="font-40 font-extrabold font-white">
              Connect, engage, and convert across multiple communication
              channels.
            </h2>
          </motion.div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <motion.a
              href="/contact?utm_source=crm+system+singapore&utm_medium=Get+started"
              className="secondaryButton font-24 mob-w-100"
              whileInView={{ opacity: [0, 1], y: [50, 0] }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              Get started
            </motion.a>
          </div>
        </div>
      </section>
    </main>
  );
};

export default multichannelNew;
